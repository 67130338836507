import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from '../components/Hero';
import SEO from '../components/SEO';
import HeaderWithLine from '../components/HeaderWithLine';
import ContactFormCom from '../components/ContactFormCom';
import StyledButton from '../components/Button';

const StyledWrapper = styled.div`
  background-color: #f5f5f5;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const ContentWrapper = styled.div`
  background-color: #f5f5f5;
  margin: 20px auto 20px auto;
  font-size: ${({ theme }) => theme.font.size.xs};

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.s};
  }
`;

const AboutUsPhoto = styled(GatsbyImage)`
  width: 200px;
  height: 100%;
  border: 2px solid #fff;
  box-shadow: 10px 10px 5px #ccc;
  margin: 4rem auto;

  ${({ theme }) => theme.mq.tablet} {
    width: 250px;
  }
`;

const AboutUsWrapper = styled.div`
  margin: 50px;
  line-height: 1.5;
`;

const StyledDescription = styled.div`
  padding-bottom: 2rem;
  max-width: 800px;
  margin: 0 auto;
`;

const StyledSignature = styled.div`
  margin: 2rem 0;
  text-align: left;
`;

const StyledOwnerName = styled.div`
  padding-top: 1rem;
  font-style: italic;
`;

const AboutCEO = styled.div`
  justify-content: center;
  align-content: center;
  align-items: center;
  display: grid;

  ${({ theme }) => theme.mq.desktop} {
    grid-template-columns: minmax(300px, 450px) minmax(300px, 450px);
  }
`;

const WhiteButton = styled(StyledButton)`
  background-color: white;
`;

export default function AboutPage({ data }) {
  const { singlePage } = data;
  const ceoPhoto = data.singlePage.pageimage.find(
    (img) => img.name === 'Marcin'
  );

  return (
    <>
      <SEO
        title="O nas - Realizacja terenów zieleni"
        description="Realizacja terenów zieleni miejskiej, ogrodów przydomowych, zielonych dachów, montaż systemów automatycznego nawadniania, wynajem mini koparko-ładowarki AVANT. Poznań i okolice."
      />
      <Hero image={singlePage.image} text={singlePage.description} />
      <StyledWrapper>
        <HeaderWithLine>O NAS</HeaderWithLine>
        <ContentWrapper>
          <AboutUsWrapper>
            <StyledDescription>
              Jesteśmy firmą usługową zajmującą się szeroko pojętą dziedziną
              architektury krajobrazu oraz florystyki. Firma została założona w
              2016 roku. Tworzą ją{' '}
              <b>wykształceni ogrodnicy oraz architekt krajobrazu.</b>
            </StyledDescription>
            <StyledDescription>
              Zajmujemy się kompleksową realizacją{' '}
              <b>
                terenów zieleni miejskiej, ogrodów przydomowych, zielonymi
                dachami, ogrodami wertykalnymi, montażem systemów automatycznego
                nawadniania oraz usługami Mini koparko ładowarką AVANT
              </b>
              . Ściśle współpracujemy ze specjalistami z naszej branży oraz
              branż towarzyszących, głównie budownictwo.
            </StyledDescription>
            <AboutCEO>
              <div>
                <AboutUsPhoto
                    image={ceoPhoto.image.asset.gatsbyImageData}
                  alt="Marcin Weber"
                />
              </div>
              <div>
                <StyledDescription>
                  Jestem głównym założycielem oraz osobą zarządzającą działem
                  Zieleni. <b>Absolwentem Uniwersytetu Przyrodniczego</b> na
                  wydziale Ogrodnictwa i Architektury Krajobrazu w Poznaniu.
                  Posiadam{' '}
                  <b>
                    bogate doświadczenie w tworzeniu oraz pielęgnacji terenów
                    zieleni
                  </b>
                  , które nabyłem pracujących w branży od momentu ukończenia
                  studiów, jako ogrodnik oraz kierownik robót ogrodniczych.
                </StyledDescription>
                <StyledSignature>
                  <div>Pozdrawiam</div>
                  <StyledOwnerName>Marcin Weber</StyledOwnerName>
                </StyledSignature>
                <WhiteButton as={Link} to="/kontakt">
                  Napisz do mnie
                </WhiteButton>
              </div>
            </AboutCEO>
          </AboutUsWrapper>
        </ContentWrapper>
      </StyledWrapper>
      <ContactFormCom />
    </>
  );
}

export const query = graphql`
  query SinglePageAboutQuery {
    singlePage: sanitySinglepage(name: { eq: "O nas" }) {
      id
      name
      description
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      pageimage {
        id
        name
        image {
          asset {
            id
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
