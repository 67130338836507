import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: ${({ theme }) => theme.primaryGreener};
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 280px;
  padding: 2rem;
  cursor: pointer;
  color: black;
  transition: all 0.5s;
  position: relative;

  :hover {
    background-color: white;
  }

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
  }

  :hover::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border: 1px solid #ababab;
    transform: scale(1.2, 1.2);
  }

  :hover::after {
    opacity: 1;
    transform: scale(1, 1);
  }
`;

export default StyledButton;
